import React, { createContext, useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import configs from "../../utils/configs";

// TODO: We really shouldn't include these dependencies on every page. A dynamic import would work better.
import jwtDecode from "jwt-decode";
import AuthChannel from "../../utils/auth-channel";
import {
  getUserManager,
  hasCodeInUrl,
  redirectToCorrectPath,
  initCredentials,
  signOut as openidSignOut,
  willRedirect,
  removeUser,
  removeSearchParam
} from "../../utils/auth-open-id";
import { connectToReticulum } from "../../utils/phoenix-utils";
import { SpinWhileTrue } from "../layout/SpinWhileTrue";

export const AuthContext = createContext();

async function checkIsAdmin(socket, store) {
  // TODO: Doing all of this just to determine if the user is an admin seems unnecessary. The auth callback should have the isAdmin flag.
  const retPhxChannel = socket.channel("ret", { hub_id: "index", token: store.state.credentials.token });

  const perms = await new Promise(resolve => {
    retPhxChannel
      .join()
      .receive("ok", () => {
        retPhxChannel
          .push("refresh_perms_token")
          .receive("ok", ({ perms_token }) => {
            const perms = jwtDecode(perms_token);
            retPhxChannel.leave();
            resolve(perms);
          })
          .receive("error", error => {
            console.error("Error sending refresh_perms_token message", error);
          })
          .receive("timeout", () => {
            console.error("Sending refresh_perms_token timed out");
          });
      })
      .receive("error", error => {
        console.error("Error joining Phoenix Channel", error);
      })
      .receive("timeout", () => {
        console.error("Phoenix Channel join timed out");
      });
  });

  const isAdmin = perms.postgrest_role === "ret_admin";

  configs.setIsAdmin(isAdmin);

  return isAdmin;
}

const noop = () => {};

export function StorybookAuthContextProvider({ children }) {
  const [context] = useState({
    initialized: true,
    isSignedIn: true,
    isAdmin: true,
    token: "abc123",
    email: "foo@bar.baz",
    userId: "00000000",
    signIn: noop,
    verify: noop,
    signOut: noop
  });
  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
}

StorybookAuthContextProvider.propTypes = {
  children: PropTypes.node,
  store: PropTypes.object.isRequired
};

export function AuthContextProvider({ children, store }) {
  const [isLoading, setIsLoading] = useState(true);
  const [userManager] = useState(getUserManager());
  const isMountedRef = useRef(false);
  const signIn = useCallback(
    async email => {
      if (configs.USE_OPEN_ID) {
        setIsLoading(true);
        await userManager.signinRedirect();
        return;
      }

      const authChannel = new AuthChannel(store);
      const socket = await connectToReticulum();
      authChannel.setSocket(socket);
      const { authComplete } = await authChannel.startAuthentication(email);
      await authComplete;
      await checkIsAdmin(socket, store);
    },
    [store, userManager]
  );

  const verify = useCallback(
    async authParams => {
      const authChannel = new AuthChannel(store);
      const socket = await connectToReticulum();
      authChannel.setSocket(socket);
      await authChannel.verifyAuthentication(authParams.topic, authParams.token, authParams.payload);
    },
    [store]
  );

  const signOut = useCallback(async () => {
    if (configs.USE_OPEN_ID) {
      openidSignOut(undefined, store);
    }
    configs.setIsAdmin(false);
    store.update({ credentials: { token: null, email: null } });
    await store.resetToRandomDefaultAvatar();
  }, [store]);

  const [context, setContext] = useState({
    initialized: false,
    isSignedIn: !!store.state.credentials && !!store.state.credentials.token,
    isAdmin: configs.isAdmin(),
    email: store.state.credentials && store.state.credentials.email,
    userId: store.credentialsAccountId,
    signIn,
    verify,
    signOut
  });

  // Trigger re-renders when the store updates
  useEffect(() => {
    const onStoreChanged = () => {
      const isSignedIn = !!store.state.credentials && !!store.state.credentials.token;
      setContext(state => ({
        ...state,
        isSignedIn,
        isAdmin: isSignedIn ? configs.isAdmin() : false,
        email: store.state.credentials && store.state.credentials.email,
        userId: store.credentialsAccountId
      }));
    };

    store.addEventListener("statechanged", onStoreChanged);

    // Check if the user is an admin on page load
    const runAsync = async () => {
      if (store.state.credentials && store.state.credentials.token) {
        const socket = await connectToReticulum();
        return checkIsAdmin(socket, store);
      }

      return false;
    };

    runAsync()
      .then(isAdmin => {
        setContext(state => ({ ...state, isAdmin }));
      })
      .catch(error => {
        console.error(error);
        setContext(state => ({ ...state, isAdmin: false }));
      });

    return () => {
      store.removeEventListener("statechanged", onStoreChanged);
    };
  }, [store, setContext, isLoading]);

  useEffect(() => {
    let isMounted = true;
    isMountedRef.current = true;
    if (!configs.USE_OPEN_ID) {
      removeUser();
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    (async () => {
      if (!userManager) {
        return;
      }
      const user = await userManager.getUser();
      const needToRedirect = willRedirect();
      // isMountedRef cannot be used here as its value is updated by next useEffect.
      // We intend to keep context of current useEffect.
      if (isMounted && (!user || user.expired)) {
        // If the user is returning back from the OIDC provider, get and set the user data.
        if (hasCodeInUrl(location)) {
          try {
            const user = await userManager.signinCallback();
            if (user) {
              if (needToRedirect) {
                //remove saved path and remove redirect path
                redirectToCorrectPath();
              } else {
                // setUserData(user);
                await initCredentials(store);
                removeSearchParam();
              }
              // onSignIn
            }
          } catch (error) {
            console.error(error);
            throw error;
          }
        }
      }
      // Otherwise if the user is already signed in, set the user data.
      else if (isMountedRef.current) {
        //clear Stale info
        await userManager.clearStaleState();
        // setUserData(user);
        await initCredentials(store);
      }
      if (needToRedirect === false) {
        setIsLoading(false);
      }
    })();
    return () => {
      isMounted = false;
      isMountedRef.current = false;
    };
  }, [store, userManager]);

  return (
    <AuthContext.Provider value={context}>
      {" "}
      <SpinWhileTrue isSpinning={isLoading}>{children}</SpinWhileTrue>{" "}
    </AuthContext.Provider>
  );
}

AuthContextProvider.propTypes = {
  children: PropTypes.node,
  store: PropTypes.object.isRequired
};
