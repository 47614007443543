import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import configs from "./configs";
const REDIRECT_PATH_KEY = "REDIRECT";
let userManager = null;
let hubInfo = null;
let isEventAdded = false;
const storageType = configs.OIDC_STORAGE_TYPE === "session" ? window.sessionStorage : window.localStorage;
const oidcConfig = {
  staleStateAgeInSeconds: 60,
  authority: `https://${configs.CLASS_MANAGER}/openid/`,
  clientId: configs.CLIENT_ID,
  responseType: "code",
  autoSignIn: configs.AUTO_SIGN_IN ? true : false,
  userStore: new WebStorageStateStore({ store: storageType }),
  // redirectUri: "https://hubs.local:9090"
  redirectUri: configs.REDIRECT_URL ? configs.REDIRECT_URL : `${window.location.origin}/signin`
};
export const hasCodeInUrl = location => {
  const searchParams = new URLSearchParams(location.search);
  const hashParams = new URLSearchParams(location.hash.replace("#", "?"));

  return (
    !!searchParams.has("code") ||
    !!searchParams.has("id_token") ||
    !!searchParams.has("session_state") ||
    !!hashParams.has("code") ||
    !!hashParams.has("id_token") ||
    !!hashParams.has("session_state")
  );
};
export const removeSearchParam = () => {
  // Get the current URL
  const url = new URL(window.location.href);
  // Remove the specified search parameter
  url.searchParams.delete("code");
  url.searchParams.delete("state");
  // Update the browser URL without reloading the page
  window.history.replaceState({}, document.title, url.toString());
};
export const initUserManager = props => {
  const {
    authority,
    clientId,
    clientSecret,
    redirectUri,
    silentRedirectUri,
    postLogoutRedirectUri,
    responseType,
    scope,
    automaticSilentRenew,
    loadUserInfo,
    popupWindowFeatures,
    popupRedirectUri,
    popupWindowTarget,
    extraQueryParams,
    metadata,
    staleStateAgeInSeconds,
    userStore
  } = props;
  return new UserManager({
    authority: authority || "",
    client_id: clientId || "",
    client_secret: clientSecret,
    redirect_uri: redirectUri || "",
    silent_redirect_uri: silentRedirectUri || redirectUri,
    post_logout_redirect_uri: postLogoutRedirectUri || redirectUri,
    response_type: responseType || "code",
    scope: scope || "openid",
    loadUserInfo: loadUserInfo || true,
    popupWindowFeatures: popupWindowFeatures,
    popup_redirect_uri: popupRedirectUri,
    popupWindowTarget: popupWindowTarget,
    automaticSilentRenew,
    extraQueryParams,
    metadata: metadata,
    staleStateAgeInSeconds,
    userStore
  });
};
export const getUserManager = (props = {}) => {
  if (userManager === null) {
    userManager = initUserManager({ ...oidcConfig, ...props });
  }
  return userManager;
};

export const saveCurrentPathForRedirect = () => {
  localStorage.setItem(REDIRECT_PATH_KEY, window.location.href);
};

export const willRedirect = () => {
  if (localStorage.getItem(REDIRECT_PATH_KEY)) {
    return true;
  } else {
    return false;
  }
};
export const redirectToCorrectPath = () => {
  const path = localStorage.getItem(REDIRECT_PATH_KEY);
  if (path) {
    localStorage.removeItem(REDIRECT_PATH_KEY);
    location.replace(path);
  }
};

export const fetchHubsTokenInfo = async user => {
  try {
    const res = await fetch(`https://${configs.CLASS_MANAGER}/api/v1/account/hubs_profile/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${user.access_token}`,
        "imedu-token": configs.IMEDU_TOKEN
      }
    });
    if (!res.ok) {
      throw new Error(`status not OK, status:${res.status}`);
    }
    hubInfo = await res.json();
    return hubInfo;
  } catch (error) {
    console.error(error);
    console.error("LogIn failed because failed to receive hub Token");
    return null;
  }
};
export const getHubInfo = () => {
  return hubInfo;
};
export const signIn = async args => {
  const userManager = getUserManager();
  saveCurrentPathForRedirect();
  await userManager.signinRedirect(args);
};
export const signOut = async (hubChannel, store) => {
  const userManager = getUserManager();
  if (hubChannel) {
    await hubChannel.signOut();
  }
  await userManager.removeUser();
  hubInfo = null;
  store.update({ credentials: { email: null, token: null } });
  //   await signOutHooks();
};
export const removeUser = async () => {
  try {
    const userManager = getUserManager();
    const user = await userManager.getUser();
    if (user) {
      await userManager.removeUser();
      return;
    }
    hubInfo = null;
  } catch (error) {
    console.error(error);
  }
};

const silentReinvokeToken = async store => {
  // setTimeout(silentReinvokeToken.bind(null, store), 4000);
  try {
    const userManager = getUserManager();
    const user = await userManager.signinSilent();
    if (user) {
      const hubInfo = await fetchHubsTokenInfo(user);
      if (hubInfo) {
        store.update({ credentials: { email: null, token: hubInfo.hubs_token } });
      }
    }
    return user;
  } catch (error) {
    const hubChannel = APP && APP.hubChannel ? APP.hubChannel : undefined;
    signOut(hubChannel, store);
    console.error(error);
  }
};
export const initCredentials = async store => {
  const userManager = getUserManager();
  window.UM = userManager;
  let user = await userManager.getUser();
  if (user && !configs.USE_OPEN_ID) {
    await userManager.removeUser();
    hubInfo = null;
    return;
  }
  if (user && user.expired) {
    user = await silentReinvokeToken(store);
  } else if (user) {
    const hubInfo = await fetchHubsTokenInfo(user);
    if (hubInfo) {
      store.update({ credentials: { email: null, token: hubInfo.hubs_token } });
    } else {
      await userManager.removeUser();
    }
  }
  if (!isEventAdded && user) {
    userManager.events.addAccessTokenExpiring(silentReinvokeToken.bind(null, store));
    // setTimeout(silentReinvokeToken.bind(null, store), 10000);
    // userManager.events.addSilentRenewError(onSilentRenewError);
    isEventAdded = true;
  }
};
